import { isEmail } from "validator";

const validateEmail = value => {
  if (!isEmail(value)) {
    return "This is not a valid email.";
  }
  else {
    return null;
  }
};

export { validateEmail }
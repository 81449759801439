import React, {useEffect, useState} from "react";
import moment from "moment";
import {useInterval} from "../../hooks/useInterval";
import {Button, Card, Col, Container, ListGroup, Row} from "react-bootstrap";
import {useLocation} from 'react-router-dom';
import {RingLoader} from "react-spinners";
import ListBreaches from "./ListBreaches/ListBreaches";
import "./Report.css";

function Report(route, navigate) {
    const location = useLocation();

    const loaderColor = "#e64900";
    const loaderSpeedMultiplier = 0.8;

    const [reportRefreshInterval, setReportRefreshInterval] = useState(3000);
    const [reportId, setReportId] = useState('');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState({
        response_json: []
    });

    // TODO: Convert this to a custom hook that will strip the user ids from the url.
    useEffect(() => {
        console.log("Running Report useEffect");
        if (process.env.NODE_ENV === 'production') {
            console.log("pageview /report")
            window.gtag("set", "page", "/report");
            window.gtag("send", "pageview");
        }
    }, []);

    const minMax = (items) => {
        return items.reduce((acc, val) => {
            acc[0] = ( acc[0] === undefined || val < acc[0] ) ? val : acc[0]
            acc[1] = ( acc[1] === undefined || val > acc[1] ) ? val : acc[1]
            return acc;
        }, []);
    }

    const getTimeSinceLastBreach = () => {
        const breachMoments = data.response_json.map(breach => moment(breach.BreachDate));
        const maxBreach = moment.max(breachMoments);
        const timeSinceLastBreach = moment.duration(moment() - maxBreach)
        const daysSinceLastBreach = Math.trunc(timeSinceLastBreach.asDays());
        const monthsSinceLastBreach = Math.trunc(timeSinceLastBreach.asMonths());
        const yearsSinceLastBreach = Math.trunc(timeSinceLastBreach.asYears());

        if (daysSinceLastBreach < 1) {
            return "Today";
        } else if (daysSinceLastBreach >= 1 && daysSinceLastBreach < 2) {
            return daysSinceLastBreach + " day ago";
        } else if (daysSinceLastBreach < 365) {
            return daysSinceLastBreach + " days ago";
        } else if (daysSinceLastBreach < 730) {
            return monthsSinceLastBreach + " months ago";
        } else {
            return yearsSinceLastBreach + " years ago";
        }
    }

    const getReport = (id) => {
        let url = new URL(`https://${process.env.REACT_APP_API_HOST}/v1/reports/${location.state.report_id}`);

        return fetch(url.toString())
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.status);
                }
                else {
                    return {
                        status: response.status,
                        data: response.status === 200 ? response.json() : null
                    };
                }
            });
    }

    useInterval(async () => {
        // TODO: If not processing.
        getReport().then(response => {
            if (response.status === 200) {
                response.data.then(data => {
                    //console.log(data);
                    setData(data);
                    setLoading(false);
                })
            }
        });
    }, loading ? reportRefreshInterval : null);

    // TODO: Move Report and loader to subcomponents.
    return (
        <Row className="report-bg">
            <Container expand="lg">
                {loading ?
                    <Col className="align-content-center" lg={{span: 6, offset: 3}}>
                        <Row>
                            <h3 className="report-transition-text report-title-margin text-center">
                                Generating threat report for {location.state.email}...
                            </h3>
                        </Row>
                        <Row className="justify-content-center">
                            <RingLoader
                                className="mt-5"
                                color={loaderColor}
                                speedMultiplier={loaderSpeedMultiplier}
                                loading={loading}
                                size={150}
                            />
                        </Row>
                    </Col>
                : error ?
                    "Error!"
                : data ?
                    <Col lg={{span: 6, offset: 3}}>
                        <h3 className="report-transition-text report-title-margin text-center">
                            Summary of your cyber risk exposure:
                        </h3>
                        <div className="card-border mt-4">
                            <Card id="report">
                                <div className="card-container">
                                    <Card.Header className="report-header p-4">
                                        <Row>
                                            <Col className="report-title">
                                                Threat Report&#8482;
                                            </Col>
                                            <Col className="report-title-email text-end">
                                                Email: &nbsp;{location.state.email}
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={{span: 6}} sm={{span: 12}}>
                                                <div className="report-number mt-2">{data.response_json.length}</div>
                                                <div className="report-label">Breach Exposures</div>
                                                <br/>
                                            </Col>
                                            <Col lg={{span: 6}} sm={{span: 12}}>
                                                <div className="report-number mt-2">
                                                    {data.response_json.length > 0 ? getTimeSinceLastBreach() : "N/A"}
                                                </div>
                                                <div className="report-label">
                                                    {data.response_json.length > 0 ? "Most recent exposure" : "No recent exposure"}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </div>
                            </Card>
                        </div>
                        {data.response_json.length > 0 ? <ListBreaches breaches={data.response_json} /> : ""}
                    </Col>
                : ""}
            </Container>
        </Row>
    );
}

export default Report;

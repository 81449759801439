import React from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate
} from "react-router-dom";
import {Button, Navbar, Container, Nav} from "react-bootstrap";
import {useScrollToTop} from "./hooks/useScrollToTop"
import './App.css';

function App() {
  let navigate = useNavigate();
  let location = useLocation();

  useScrollToTop();
  return (
      <div>
        <Navbar fixed="top" expand="lg">
          <Container>
            <Navbar.Brand href="/">Threat.com</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <main>
          <Container fluid>
            <Outlet />
          </Container>
        </main>
      </div>
  );
}

export default App;

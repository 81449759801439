import React, {useState} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom"
import {validateEmail} from "../../../services/Validation";
import "./AboveTheFold.css";
import arrow from "./arrow-right.svg";


function AboveTheFold() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  let navigate = useNavigate();

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.gtag("set", "page", "/");
      window.gtag("send", "pageview");
    }
  }, []);

  const handleSubmit = function(event) {
    event.preventDefault();
    setValidationErrors({});
    let errors = {}

    const emailValidationError = validateEmail(email);
    if (emailValidationError !== null) {
      errors.email = emailValidationError;
    }

    if (Object.keys(errors).length === 0) {
      setSubmitted(true);

      let body = {
        email : email,
      }

      return fetch(`https://${process.env.REACT_APP_API_HOST}/v1/emails`, {
        method: 'POST',
        body: JSON.stringify(body)
      })
      .then(response => {
        const status = response.status;
        response.json().then((data) => {
          if (status === 201 || status === 422) {
            navigate(`/report/${data.email_id}`, { state: {
              email: email,
              email_id: data.email_id,
              report_id: data.report_id
            }});
            if (process.env.NODE_ENV === 'production') {
              window.gtag("event", "email_form_submission");
            }
          }
          else if (status === 400) {
            setValidationErrors(data.errors);
          }
          else {
            console.log("Shit is going down.");
          }
        });
      });
    }
    else {
      return setValidationErrors(errors);
    }
  }

  /* TODO: Add reusable handler for multi-field forms:
           https://stackoverflow.com/questions/70214394/form-validation-react-function-component-with-usestate
   */
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  return (
    <Row className="atf-bg">
      <div className="atf-illustration">
        <Container expand="lg">
          <Col lg={{span: 7}}>
            <div className="atf-title atf-title-margin">Check your cyber risk exposure</div>
            <div className="atf-secondary my-4">
              Enter your <strong>business email</strong> to identify threats to your organization like
              malware-infected employees, stolen credentials, and data breaches.
            </div>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group as={Row} className="mb-3">
                <Col lg={{ span: 8 }} md={{ span: 8}} sm={{ span: 12 }}>
                  <Form.Control
                      type="text"
                      placeholder="Enter your email (ex. john@threat.com)"
                      onChange={handleEmailChange}
                      value={email}
                      isInvalid={'email' in validationErrors}
                  >
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.email && validationErrors.email}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1">
                <Col lg={{ span: 8 }} md={{ span: 8}} sm={{ span: 12 }}>
                  <Button className="atf-call-to-action" type="submit">
                    See your threat report
                    <img
                        src={arrow}
                        alt="arrow"
                        className="filter-white"
                        width="26"
                        height="26"
                        style={{marginLeft: '8px', marginBottom: '3px'}}
                    />
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Container>
      </div>
    </Row>
  );
}

export default AboveTheFold;
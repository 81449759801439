import React from "react";
import {Col, Row} from "react-bootstrap";
import "./ListBreaches.css";


function ListBreaches({breaches}) {
    return (
        <>
            <h3 className="report-transition-text text-start mt-5 mb-4">
                Data breaches you were exposed in:
            </h3>
            <div className="report-description-text">
                A "breach" is an incident where data was unintentionally leaked to the public.&nbsp; This
                list details the associated companies and context regarding the exposure.
            </div>
            <hr className="report-divider" />
            {breaches.map((breach, index) => (
                <Row className="my-4" key={breach.Name}>
                    <Col lg={{span: 3}} sm={{span: 12}} className="text-center text-md-left">
                        <img className="breach-logo mt-2 mb-3"
                             src={breach.LogoPath}
                             alt={breach.Name}
                        />
                    </Col>
                    <Col lg={{span: 9}} sm={{span: 12}}>
                        <div>
                            <span className="breach-label">{breach.Name}:&nbsp;&nbsp;&nbsp;</span>
                            <span className="breach-description" dangerouslySetInnerHTML={{__html: breach.Description}}></span>
                        </div>
                        <div className="mt-2">
                            <span className="breach-label">Compromised data:&nbsp;&nbsp;&nbsp;</span>
                            <span className="breach-description">{breach.DataClasses.join(', ')}</span>
                        </div>
                    </Col>
                </Row>
            ))}
        </>
    );
}

export default ListBreaches;

import React, {useEffect} from "react";
import AboveTheFold from "./AboveTheFold/AboveTheFold";

export default function Home() {

    return (
        <>
            <AboveTheFold />
        </>
    );
}
